import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, throwError, Observable } from 'rxjs';
import { switchMap, catchError, map, retry, filter } from 'rxjs/operators';
import { UserRecord, DSARoleRecord } from '../data';
import { DSAError } from './errors';
import { LinkService } from './link.service';
import { Store } from '@ngxs/store';
import { UserState, UserStateModel } from './user.state';

/** 負責處理登入相關動作與狀態，核心程式使用。 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private link: LinkService,
    private store: Store
  ) {}

  /** 取得個人資料 */
  async getMyInfo() {
    return this.http.get<UserRecord>('/auth/getMyInfo').toPromise();
  }

  /** 確認登入狀態 */
  async checkLoginState(): Promise<boolean> {
    const { success, signed } = await this.http.get<{ success: boolean, signed: boolean }>('/auth/available').toPromise();
    if (success && signed) {
      try {
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  /** 同步最新身份資料（同步成功可在呼叫 getMyInfo 取得個人最新資訊） */
  async syncCurrentRole() {
    return this.http.get<{Success: boolean}>('/auth/syncCurrentRole').toPromise();
  }

  /** 登出系統。 */
  public logout(logoutList: string[] = []) {
    return this.http.get(`/auth/logout`).pipe(
      switchMap(rsp => {
        const { state } = rsp as { state: string; };
        if (state === 'success') {
          if (logoutList.indexOf('google') !== -1) {
            const googleIframe = document.createElement('iframe');
            googleIframe.setAttribute('style', 'display:none;');
            googleIframe.setAttribute('src', 'https://www.google.com/accounts/Logout');
            document.body.appendChild(googleIframe);
          }

          return new Observable<boolean>(ob => {
            ob.next(true);
            ob.complete();
          });

        } else {
          return throwError({ error: '！！登出失敗了！！' });
        }
      }),
      filter(v => v) // 登出成功。
    )
    .subscribe(v => {
      const user = this.store.selectSnapshot<UserStateModel>(UserState);
      let nextURL = `${window.location.protocol}//${window.location.host}`;

      if(user.customize_signin) {
        nextURL += `/d/${user.customize_signin}`;
      } else {
        nextURL += `/intl?lang=${user.authInfo.language || 'zh-TW'}`;
      }

      window.location.assign(`https://auth.ischool.com.tw/logout.php?next=${encodeURIComponent(nextURL)}`);
    });
  }

  /** 取得 QR Code 的 Code。 */
  public getCode(): Observable<any> {
    const url = this.link.getLinkout('https://auth.ischool.com.tw/code/link/get_code.php');
    return this.http.get(url, {
      withCredentials: true
    }).pipe(
      retry(3),
      map(v => v),
      catchError(err => {
        return of(false);
      })
    );
  }

  /** 取得 access token */
  public getAccessToken(forceRenew: boolean = false): Observable<string> {
    // ?renew=true
    let params = undefined;
    if(forceRenew) {
      params = {
        renew: forceRenew ? 'true' : 'false',
      }
    }

    return this.http.get('/auth/getAccessToken', { params }).pipe(
      map((v: any) => v.access_token || ''),
      catchError(err => {
        alert(`系統授權已失效，請重新登入系統。`);
        throw err;
      })
    );
  }

  /** 取得在 DSA 中的角色資訊 */
  public getDSARole(dsns: string, accessToken: string): Observable<DSARoleRecord[] | undefined> {
    const path = [
      `https://dsns.ischool.com.tw/${dsns}/web3.v1.guest/GetRoleInfo`,
      `?stt=PassportAccessToken`,
      `&AccessToken=${accessToken}`,
      `&rsptype=json`
    ].join('');
    return this.http.get(path).pipe(
      map(v => {
        // access token 過期。
        const code = (v as any)?.Header?.Status?.Code;

        switch (code) {
          case 502:
            if ((v as any)?.Header?.Status?.Message) {
              const detail = (v as any)?.Header?.Status?.Message;

              var pattern = /Authorized Scope for (.*)/;
              var match = detail.match(pattern);
              if (match) {
                  var result = match[1];
                  throw new DSAError(`Invalid Passport Authorized Scope(${result})`, '502.1', detail);
              }

              throw new DSAError(`GetRoleInfo Error(${dsns})!`, '502', detail);
            } else {
              throw new DSAError(`GetRoleInfo Error(${dsns})!`, '502', v);
            }
          case 523:
            console.log(`GetRoleInfo Error(${dsns})! => Contract Not Found(523)!`);
            break;
        }

        // 這是新舊版 DSA 造成的問題，這裡只要先這樣判斷。
        const key = '__Body';
        if (v[key]) {
          return {...v[key]};
        } else {
          return v;
        }
      }),
      map((v: any) => v?.Users?.User),
      map((records: DSARoleRecord[]) => {
        const array = [].concat(records || []);
        return array.map(r => {
          if (!r.Attributes) {
            r.Attributes = { Visibility: [] };
          }
          r.Attributes.Visibility = [].concat(r.Attributes.Visibility || []);
          return r;
        })
      }),
      catchError(err => {
        if(err instanceof DSAError) {
          throw err;
        } else {
          console.warn(err);
          return of(undefined);
        }
      }),
    );
  }

  public setMyInfo(lastName: string, firstName: string, language: string) {
    return this.http.put<{ success: boolean }>('/auth/setMyInfo', {
      lastName,
      firstName,
      language,
    }).toPromise();
  }
}
