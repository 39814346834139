import { Pipe, PipeTransform } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';

@Pipe({
  name: 'roleFormat',
})
export class RoleFormatPipe implements PipeTransform {
  constructor(private i18nextPipe: I18NextPipe) {}

  transform(value: string): unknown {
    switch (value) {
      case 'teacher': return this.i18nextPipe.transform('教師');
      case 'student': return this.i18nextPipe.transform('學生');
      case 'parent': return this.i18nextPipe.transform('家長');
      case 'guest': return this.i18nextPipe.transform('訪客');
      case 'admin': return this.i18nextPipe.transform('管理員');
      default: return value;
    }
  }
}
