import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {

  headerTitle$ = new BehaviorSubject<string>('');

  constructor() { }

  set(newTitle: string) {
    this.headerTitle$.next(newTitle);
  }
}
