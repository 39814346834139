import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copyright-content',
  styles: [`
    svg[data-icon] {
      font-size: 1rem;
    }
  `],
  template: `
<div class="p-4">
  <h2 class="mat-dialog-title" id="tCopyrightLabel">著作權政策</h2>
  <mat-dialog-content>
  <p>ischool 尊重他人著作權，ischool 服務條款亦明定， ischool 服務使用者不得侵害他人之著作權，因此，ischool 呼籲使用者同樣尊重他人之著作權。如果您認為 ischool
          服務中之任何網頁內容或使用者使用
          ischool 已侵害您的著作權，建議您利用本處理辦法提出檢舉，ischool 客服中心將儘速為您處理。 </p>
      <ul>
          <li>
              <h3>檢舉著作權侵權處理辦法</h3>
              <ol>
                  <li>
                      <h4>權利人檢舉：</h4>
                      <div>若 ischool 服務中之任何網頁內容或使用者使用 ischool 服務已侵害您的著作權，請您填寫「著作權侵害通知書」，且依該通知書所載提供下列資料
                          及聲明並經簽名或蓋章後，以傳真的方式通知
                          ischool 、或將通知書掃瞄為電子檔後作為電子郵件附件寄送予 ischool：
                          <ol type="a">
                              <li>著作權人或著作權人之代理人之姓名或名稱、聯絡地址及聯絡電話、電子信箱或傳真號碼。</li>
                              <li>被侵害之著作或製版之名稱。</li>
                              <li>侵害著作權之內容所在頁面的網址（URL）等，足使 ischool 確認該涉有侵權內容之相關資訊；並請求移除侵害著作權之內容。</li>
                              <li>聲明著作權人確係基於善意，相信該侵權內容的使用是未經過合法授權或違反著作權法。</li>
                              <li>聲明您於通知書所載相關資料均為真實，且您是著作權人或著作權人之代理人而為上開聲明。</li>
                              <li>保證上開聲明如有不實，致ischool 或他人受損害者，著作權人願負法律責任。
                                  <ul>
                                      <li>下載
                                          <a href="/assets/doc/ischool copyright infringement.docx">著作權侵權通知書
                                          <i class="iconify" data-icon="fa:download" data-inline="true"></i>
                                          </a>
                                      </li>
                                      <li>檢舉窗口 ischool E-mail 信箱：support@ischool.com.tw
                                          <br>（請將通知書掃瞄為電子檔後作為電子郵件附件寄送予 ischool，此檢舉方式不接受電子簽章。）</li>
                                      <li>其他聯繫資訊：
                                          <br>公司名稱：澔學學習系統有限公司
                                          <br>客服電話：03-533-7771</li>
                                  </ul>
                              </li>
                          </ol>
                      </div>
                  </li>
                  <li>
                      <h4>被檢舉人異議：</h4>
                      <div>倘若您遭權利人檢舉而對於權利人的主張有異議，請您填寫「著作權侵害異議通知書」，且依該通知書所載提供資料及聲明並經簽名或蓋章後，以傳真的方式通知
                          ischool、或將通知書掃瞄為電子檔後作為電子郵件附件寄送予
                          ischool。
                          <ul>
                              <li>下載
                                  <a href="/assets/doc/ischool copyright infringement dispute.docx">著作權侵害異議通知書
                                      <i class="iconify" data-icon="fa:download" data-inline="true"></i>
                                  </a>
                              </li>
                              <li>異議窗口： 同上述權利人檢舉窗口。</li>
                          </ul>
                      </div>
                  </li>
              </ol>
          </li>
          <li>
              <h3>ischool 處理原則</h3>
              <ol>
                  <li>ischool 接獲權利人的通知書後，將儘速移除權利人所主張涉有侵害著作權之網頁內容，並依據 ischool
                      與使用者約定之聯絡方式或使用者留存之聯絡資訊，轉知使用者涉有侵權情事。ischool
                      得將處理情況，包括權利人的姓名、電子郵件或電話等提供予該使用者，俾該使用者得直接與權利人溝通解決爭議。若著作權侵權通知不完備者，ischool 將會通知權利人補正相關資訊。</li>
                  <li>請注意，使用者的網頁內容倘若經權利人主張涉有侵害其著作權者，該內容一旦經 ischool 刪除後，ischool 即無法再行回復。又使用者如有三次涉有侵權情事者，ischool
                      將終止該使用者全部或部分之服務。</li>
                  <li>但倘若使用者對於權利人主張的侵權情事有異議，該使用者得檢具通知文件要求 ischool 將該通知文件轉知權利人以表示異議；若使用者的通知不完備者，ischool
                      將會通知使用者補正相關資訊。ischool
                      於接獲前項之通知文件後，會立即將該通知文件轉送給權利人。若權利人未於接獲該通知文件之次日起十個工作日內，提出已對該使用者提出訴訟之證明者，該使用者將可以自行重新上傳該內容。</li>
                  <li>前述權利人或使用者的通知若不完備者，ischool 得以其所提供之電子郵件或傳真通知補正相關資訊。</li>
                  <li>權利人或使用者必須於接到 ischool 補正通知之次日起五個工作日內完成補正，屆期未補正或未完全補正者，依著作權法之規定即為未提出通知。</li>
                  <li>依據 ischool 隱私權政策條款規定，除經使用者同意或於提供服務之目的者外，ischool
                      僅得依法令或政府機關之要求，提供使用者之識別資料予第三人。因之，您如依本辦法提出檢舉，ischool
                      僅會依您的請求移除該侵權內容，不會提供您有關該使用者之識別資料。您如欲取得該使用者資料，您應向地方法院檢察署或刑事警察局提出告訴，ischool
                      將於前開單位來函要求提供使用者資料後，即刻配合提供之。</li>
              </ol>
          </li>
      </ul>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>關閉</button>
  </mat-dialog-actions>
</div>
  `
})
export class CopyrightContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}
