import { RoleRecord } from './role_base';

export class AdminRecord extends RoleRecord {

  roleName: Role = 'admin';

  public static create(rec: Partial<AdminRecord>) {
    return Object.assign(new AdminRecord(), rec);
  }
}
