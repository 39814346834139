import { GetAccessToken } from './auth.action';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  private inited = false;

  constructor(
    private auth: AuthService,
    private store: Store
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.inited) { return true; }

    this.inited = true;

    return this.checkLogin(state.url);
  }

  async checkLogin(restoreUrl: string) {
    const state = await this.auth.checkLoginState();
    if (state) { // 登入的話

      // TODO: 取得 Access Token 錯誤應該要導向一個頁面。
      await this.store.dispatch(new GetAccessToken()).toPromise();

    } else {
      const authState = encodeURIComponent(JSON.stringify({
        next: restoreUrl
      }));
      window.location.assign(`/d/default?state=${authState}`);
    }

    return state;
  }
}
