import { Role } from '../platform-web3/user.state';
import { CalculateVisibility } from './visibility_model';

export class RoleRecord {
  /** 校務中的 admin 系統編號、teacher 系統編號、student_parent 系統編號、guest */
  id: number;
  /** 姓名、小孩姓名 */
  name: string;
  /** 學校名稱 */
  schoolName: string;
  /** DSNS */
  dsns: string;

  roleName: Role;

  /** 角色的額外屬性，例如 tags、visibility。 */
  attributes: Attributes;

  /** 是否為超級使用者。 */
  get isSuperuser() {
    const superuser: CalculateVisibility = '<超級使用者>';
    const visibilities = this.attributes?.visibility || [];
    return visibilities.indexOf(superuser) >= 0;
  }
}

export interface Attributes {
  visibility: string[];
}
