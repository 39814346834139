export const environment = {
  production: true,
  appName: '1Campus - 智慧校園整合服務',
  globalSchoolDSNS: '1campus.net',
  globalSchoolName: '1Campus School',
  postmessageOrigins: ['https://1campus.net'],
  greening: 'https://greening.ischool.com.tw/dsa/greening',
  web2_host: 'https://legacy-web2.ischool.com.tw/deployment/web3_agent_zce888638a0e41d1a9013',
  logIdentity: '1campus.net',
};
