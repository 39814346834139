import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  /** 呼叫 API 時基礎位置 */
  public get API_BASE(): string {
    return 'service';
  }

  /** 呼叫 API 時管理者的 base url。 */
  public get API_ADMIN_BASE(): string {
    return `${this.API_BASE}/admin`;
  }

  /** 呼叫 API 時一般用戶的 private url。 */
  public get API_PRIVATE_BASE(): string {
    return `${this.API_BASE}`;
  }

  public get DSNS_HOST(): string {
    return 'https://dsns.ischool.com.tw';
  }
}
