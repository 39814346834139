import { RoleWithID } from './user.state';

/** 從 Auth(Greening) 下載 DSA Links 資料。 */
export class LoadUserSchoolLinks {
  static readonly type = '[user] load dsa links';
  constructor() {}
}

export class RemoveUserSchoolLink {
  static readonly type = '[user] remove dsa link';
  constructor(public dsns: string) {}
}

export class SetSchoolRoleRetrieved {
  static readonly type = '[user] set school role retrieve';
  constructor(public dsns: string) {}
}

export class SetSelectedSchool {
  static readonly type = '[user] set selected school';
  constructor(public dsns: string) {}
}

export class SetSelectedRole {
  static readonly type = '[user] set selected role';
  constructor(public role: RoleWithID, public displayName: string) {}
}
