import { RoleRecord } from './role_base';

export class StudentRecord extends RoleRecord {

  /** 班級名稱 */
  className: string;
  /** 家長代碼 */
  parentCode: string;

  roleName: Role = 'student';

  public static create(rec: Partial<StudentRecord>) {
    return Object.assign(new StudentRecord(), rec);
  }
}
