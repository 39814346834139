import { RoleRecord } from './role_base';

export class TeacherRecord extends RoleRecord {

  roleName: Role = 'teacher';

  public static create(rec: Partial<TeacherRecord>) {
    return Object.assign(new TeacherRecord(), rec);
  }
}
