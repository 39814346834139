import { RoleRecord } from './role_base';

export class ParentChildRecord extends RoleRecord {

  /** 學生系統編號 */
  studentId: number;
  /** 學生班級名稱 */
  className: string;

  roleName: Role = 'parent';

  public static create(rec: Partial<ParentChildRecord>) {
    return Object.assign(new ParentChildRecord(), rec);
  }
}
