import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/platform-web3/auth.service';
import * as dayjs from 'dayjs';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'app-auth-qrcode',
  templateUrl: './auth-qrcode.component.html',
  styleUrls: ['./auth-qrcode.component.css']
})
export class AuthQRCodeComponent implements OnInit, OnDestroy {

  qrCodeURL = '';
  errCodeMsg = '';
  qrDurationFormat = '';
  timer: any;

  constructor(
    private authSrv: AuthService,
    @Inject(I18NEXT_SERVICE) private i18nextSrv: ITranslationService,
  ) { }

  ngOnInit() {
    this.getQRCode();
  }

  /** 產生QR Code */
  async getQRCode() {
    try {
      this.errCodeMsg = '';
      clearInterval(this.timer);

      const rsp = await this.authSrv.getCode().toPromise();
      const authorize_code = rsp.authorize_code;
      if (!rsp.error) {

        let duration = dayjs.duration(1 * 60, 's'); // 分鐘
        this.qrCodeURL = `https://devapi.1campus.net/api/code/qrcode/img?chld=M&chs=232x232&cht=qr&chl=${authorize_code}&choe=UTF-8`;
        this.qrDurationFormat = `${duration.minutes().toString().padStart(2, '0')} : ${duration.seconds().toString().padStart(2, '0')}`;

        this.timer = setInterval(async () => {
          if (duration.asSeconds() <= 0) {
            clearInterval(this.timer);
            await this.getQRCode();
          } else {
            duration = dayjs.duration(duration.asSeconds() - 1, 's');
            this.qrDurationFormat = `${duration.minutes().toString().padStart(2, '0')} : ${duration.seconds().toString().padStart(2, '0')}`;
          }
        }, 1000);
      } else {
        this.errCodeMsg = rsp.error;
      }
    } catch (error) {
      this.errCodeMsg = this.i18nextSrv.t('發生錯誤').toString();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }
}
