import { Inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { environment } from '../../environments/environment';
import { SchoolLinkRecord } from '../data';
import { DSAService } from '../dsutil-ng/dsa.service';
import { Jsonx } from '../dsutil-ng/jsonx';
import { AuthService } from './auth.service';
import {
  LoadUserSchoolLinks, RemoveUserSchoolLink, SetSchoolRoleRetrieved, SetSelectedRole, SetSelectedSchool
} from './user.action';
import { LinkService } from './link.service';

export type Role = 'teacher' | 'student' | 'parent' | 'guest' | 'admin';

const greening = environment.greening;

export interface RoleWithID {
  /** dsns */
  dsns: string;
  /** 角色名稱。 */
  role: Role;
  /** 識別編號。 */
  id: number;
}

export interface AuthInfo {
  account: string;
  name: string;
  firstName: string;
  lastName: string;
  language: string;
  profile: string;
  isDomainAdmin: boolean;
}

export interface UserStateModel {
  /** 使用者顯示名稱。 */
  name: string;

  /** 使用者連結學校清單 */
  schoolLinks: SchoolLinkRecord[];

  /** 目前選擇的角色與識別編號。 */
  selectedRole: RoleWithID;

  /** Auth 中的資料 */
  authInfo: AuthInfo;

  customize_signin?: string;

  /** 目前只有「新竹」。 */
  profile?: 'hs'

  /** Gadget Source，目前用於測試情境。 */
  gadgetSource?: string;
}

export interface UserIdentity {
  role: Role;
  id: number;
  name: string;
  schoolName: string;
  dsns: string;
  className: string;
}

export const USER_STATE_TOKEN = new StateToken<UserStateModel>('user');
@State<UserStateModel>({
  name: USER_STATE_TOKEN,
  defaults: {
    name: '',
    schoolLinks: [],
    selectedRole: null,
    authInfo: {} as AuthInfo,
  }
})
@Injectable()
export class UserState {

  constructor(
    private auth: AuthService,
    private store: Store,
    private dsa: DSAService,
    private link: LinkService,
    @Inject(I18NEXT_SERVICE) private i18nextSrv: ITranslationService,
  ) {}

  @Selector()
  static authInfo(state: UserStateModel) {
    return state.authInfo;
  }

  @Selector()
  static schoolLinks(state: UserStateModel) {
    return state.schoolLinks;
  }

  /** 已選擇的 DSA(School)。 */
  @Selector()
  static selectedSchool(state: UserStateModel) {
    return state.schoolLinks.find(v => v.selected);
  }

  /** 已選擇的 Role(角色與識別編號)。 */
  @Selector()
  static selectedRole(state: UserStateModel) {
    return state.selectedRole;
  }

  /** 取得指定 dsns 的 school link。 */
  @Selector()
  static getSchoolLink(state: UserStateModel) {
    return (dsns: string) => {
      return state.schoolLinks.find(v => v.ap_name === dsns);
    };
  }

  /** 取得 1Campus School Link 資料。 */
  @Selector()
  static get1CampusLink(state: UserStateModel) {
    return state.schoolLinks.find(v => v.ap_name === environment.globalSchoolDSNS);
  }

  /**
   * 下載使用者所連結的 DSA 清單。並設定 1campus 學校中的角色資料。
   */
  @Action(LoadUserSchoolLinks)
  async loadUserDSALinks(ctx: StateContext<UserStateModel>, action: LoadUserSchoolLinks) {
    const { store } = this;

    const user = await this.auth.getMyInfo();
    // 防止 server 端沒有指定時，使用預設位置。
    this.link.gadgetSource = user.gadgetSource ? user.gadgetSource : environment.web2_host;
    user.application.forEach(app => {
      app.roles_retrieved = false;
      app.selected = false;
    });
    ctx.patchState({
      name: user.name,
      schoolLinks: [...user.application],
      authInfo: {
        name: user.name,
        lastName: user.lastName,
        firstName: user.firstName,
        account: user.account,
        language: user.language,
        profile: 'https://auth.ischool.com.tw/service/getpic.php?rd=' + Math.random(),
        isDomainAdmin: user.isDomainAdmin,
      },
      customize_signin: user.customize_signin,
      profile: user.profile,
      gadgetSource: user.gadgetSource,
    });
  }

  @Action(RemoveUserSchoolLink)
  async removeUserDSALink(ctx: StateContext<UserStateModel>, action: RemoveUserSchoolLink) {
    const { store } = this;
    const { dsns } = action;

    const gree = await this.dsa.getConnection(greening, 'user');
    const dsreq = new Jsonx(`
    <Request>
        <Application>
          <AccessPoint>${dsns}</AccessPoint>
          <Type>dynpkg</Type>
        </Application>
    </Request>`);

    const rsp = await gree.send('DeleteApplication', dsreq);

    const links = ctx.getState().schoolLinks;

    ctx.patchState({
      schoolLinks: links.filter(v => v.ap_name !== dsns),
    });
  }

  @Action(SetSchoolRoleRetrieved)
  setSchoolRoleRetrieved({getState, patchState}: StateContext<UserStateModel>, action: SetSchoolRoleRetrieved) {
    const { dsns } = action;

    const links = getState().schoolLinks.map(v => ({ ...v }));
    const found = links.find(link => link.ap_name === dsns);

    if (!found) { return; }

    found.roles_retrieved = true;

    patchState({
      schoolLinks: links
    });

  }

  @Action(SetSelectedSchool)
  setSelectedSchool({getState, patchState}: StateContext<UserStateModel>, action: SetSelectedSchool) {
    const { dsns } = action;

    const links = getState().schoolLinks.map(v => ({ ...v }));
    links.forEach(v => v.selected = (v.ap_name === dsns));

    patchState({
      schoolLinks: links,
    });
  }

  @Action(SetSelectedRole)
  setSelectedRole({ patchState }: StateContext<UserStateModel>, action: SetSelectedRole) {
    patchState({
      name: action.displayName,
      selectedRole: action.role,
    });
  }

}
