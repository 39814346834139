import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadUserSchoolLinks } from './user.action';

/**
 * 確保 DSA Links 已經下載完成。
 */
@Injectable({
  providedIn: 'root'
})
export class DSALinksGuard implements CanActivate {

  private inited = false;

  constructor(
    private store: Store
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    if (this.inited) { return true; }

    // 已經在 APP_INITIALIZER 下載過了。
    // await this.store.dispatch(new LoadUserSchoolLinks()).toPromise();

    this.inited = true;

    return true;

  }

}
