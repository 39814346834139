<mat-toolbar>
  <div class="container d-flex justify-content-between">
    <div class="d-flex justify-content-between align-items-center" *ngIf="logoUrl">
      <a class="navbar-brand ml-0 position-relative mr-3" [routerLink]="homepageUrl | async">
        <img class="logo" [src]="logoUrl" style="height: 40px;" alt="1Campus">
        <span class="dev-mode-text" [hidden]="isProduction">開發者模式</span>
      </a>
      <span class="title pl-3">{{title}}</span>
    </div>

    <div></div>

    <div class="d-flex align-items-center">
      <app-role-selector class="mr-2"></app-role-selector>
      <app-account-setting class="d-flex align-items-center"></app-account-setting>
    </div>
  </div>
</mat-toolbar>
