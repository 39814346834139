import { RoleRecord } from './role_base';

export class GuestRecord extends RoleRecord {

  roleName: Role = 'guest';

  public static create(rec: Partial<GuestRecord>) {
    return Object.assign(new GuestRecord(), rec);
  }
}
