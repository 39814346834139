import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  #gadgetSource = environment.web2_host;

  constructor() { }
 
  /** gadget 的載入來源。 */
  public get gadgetSource() {
    return this.#gadgetSource;
  }

  public set gadgetSource(value: string) {
    console.log(`gadget source set to: ${value}`);
    this.#gadgetSource = value;
  }

  /** 取得穩定登入 auth 的第三方連結。 */
  public getLinkout(target: string) {
    // 確保 access token 在最新狀態。
    return `/auth/linkout?redirect_url=${encodeURIComponent(target)}`;
  }
}
